import { Box, Divider, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import CellIcon from "./assets/images/recycle-icon";
import CheckIcon from "./assets/images/check-icon";
import FileIcon from "./assets/images/file-icon";
import FlowenPlatformImage from "./assets/images/flowen-platform";
import ParamConfig from "./assets/images/param-config";
import PhoneIcon from "./assets/images/phone-icon";
import ContentArea from "./ContentArea";
import FlowenConnectIcon from "./assets/images/flowen-connect-icon";
import FlowenAdvanceIcon from "./assets/images/flowen-advance-icon";
import BorderElementTop from "./assets/images/border-element-top";
import BorderElementBottom from "./assets/images/border-element-bottom";
import FlowenPlatformImageMobile from "./assets/images/flowen-platform-image-mobile.png";
import BorderElementTopMobile from "./assets/images/border-element-top-mobile";
import BorderElementBottomMobile from "./assets/images/border-element-bottom-mobile";

const FlowenPlatform: React.FC = () => {
  return (
    <Box
      id="FlowenPlatformSection"
      sx={{
        py: { xs: 5, sm: 10 },
        position: "relative",
        background:
          "linear-gradient(180deg, rgba(236, 236, 236, 0.12) 0%, rgba(237, 237, 237, 0.42) 100%)",
      }}
    >
      <ContentArea>
        <Fragment>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
              <Typography component="div" color="primary" variant="h2" sx={{ maxWidth: "470px" }}>
                <Box fontWeight={700} display="inline">
                  Nossa
                </Box>{" "}
                <br />
                <Box fontWeight={300} display="inline">
                  plataforma
                </Box>{" "}
              </Typography>

              <Typography
                component="div"
                color="text.secondary"
                variant="h5"
                fontWeight={300}
                sx={{ maxWidth: "430px", mt: 2 }}
              >
                A plataforma Flowen utiliza recursos computacionais avançados e disponíveis em
                nuvem, viabilizando um sistema de supervisão em tempo real as concessionárias. A sua
                tecnologia eleva o nível de segurança na gestão e elimina a necessidade de
                servidores físicos locais.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FlowenPlatformImage sx={{ display: { xs: "none", sm: "block" } }} />
              <Box
                component="img"
                src={FlowenPlatformImageMobile}
                sx={{ display: { xs: "block", sm: "none" }, margin: "auto", pt: 4 }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} rowSpacing={7} sx={{ mt: { xs: 0, sm: 4 } }}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <ParamConfig />
                <Typography
                  color="text.secondary"
                  variant="body2"
                  fontWeight={300}
                  maxWidth="370px"
                  sx={{ ml: { xs: 0, sm: 4 }, textAlign: { xs: "center", sm: "left" } }}
                >
                  Contém parametrização remota para a configuração do medidor e da plataforma
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <CellIcon />
                <Typography
                  color="text.secondary"
                  variant="body2"
                  fontWeight={300}
                  maxWidth="370px"
                  sx={{ ml: { xs: 0, sm: 4 }, textAlign: { xs: "center", sm: "left" } }}
                >
                  Integra os medidores inteligentes para um monitoramento avançado e apoio à
                  operação do sistema de medição
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <FileIcon />
                <Typography
                  color="text.secondary"
                  variant="body2"
                  fontWeight={300}
                  maxWidth="370px"
                  sx={{ ml: { xs: 0, sm: 4 }, textAlign: { xs: "center", sm: "left" } }}
                >
                  Disponibiliza informações gerenciais, análises históricas relacionadas à
                  geografia, ao estado do medidor, cadastro de usuários e mais
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <PhoneIcon />
                <Typography
                  color="text.secondary"
                  variant="body2"
                  fontWeight={300}
                  maxWidth="370px"
                  sx={{ ml: { xs: 0, sm: 4 }, textAlign: { xs: "center", sm: "left" } }}
                >
                  Oferece o Flowen App, aplicativo para o uso em campo, voltado à instalação dos
                  medidores, manutenção e a outros serviços operacionais
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              backgroundColor: "grey.400",
              width: { xs: "100%", sm: "330px" },
              height: "2px",
              margin: "auto",
              my: 10,
            }}
          ></Box>

          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "center" },
              }}
            >
              <FlowenConnectIcon
                sx={{ mb: { xs: 2, sm: 0 }, alignSelf: { xs: "center", sm: "flex-start" } }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  ml: { xs: 0, sm: 4 },
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                <Typography component="div" color="primary" variant="h4" fontWeight={300}>
                  Flowen{" "}
                  <Box fontWeight={700} display="inline">
                    Connect
                  </Box>
                </Typography>

                <Typography
                  color="text.secondary"
                  variant="body2"
                  fontWeight={300}
                  sx={{ maxWidth: "370px", mt: 2 }}
                >
                  Serviço de integração para a comunicação com os sistemas legados e outras
                  aplicações de mercado.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "center" },
                mt: { xs: 4, sm: 0 },
              }}
            >
              <FlowenAdvanceIcon
                sx={{ mb: { xs: 2, sm: 0 }, alignSelf: { xs: "center", sm: "flex-start" } }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  ml: { xs: 0, sm: 4 },
                  textAlign: { xs: "center", sm: "left" },
                  marginBottom: "auto",
                }}
              >
                <Typography component="div" color="primary" variant="h4" fontWeight={300}>
                  Flowen{" "}
                  <Box fontWeight={700} display="inline">
                    Advance
                  </Box>
                </Typography>

                <Typography
                  color="text.secondary"
                  variant="body2"
                  fontWeight={300}
                  sx={{ maxWidth: "370px", mt: 2 }}
                >
                  Módulos de análise de dados avançados para cenários específicos do cliente.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              mt: 10,
              p: { xs: 5, sm: 10 },
              borderRadius: "40px",
              backgroundColor: "background.default",
              position: "relative",
            }}
          >
            <BorderElementTop
              sx={{
                position: "absolute",
                top: "-10px",
                left: "-10px",
                display: { xs: "none", sm: "block" },
              }}
            />
            <BorderElementTopMobile
              sx={{
                position: "absolute",
                top: "-10px",
                left: "-10px",
                display: { xs: "block", sm: "none" },
              }}
            />
            <Grid item xs={12}>
              <Typography color="primary" variant="subtitle1" fontWeight={300} component="div">
                Serviços{" "}
                <Box display="inline" fontWeight={700}>
                  adicionais
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ my: 4 }}>
              <Divider />
            </Grid>

            <Grid container sx={{ mt: { xs: 0, sm: 2 } }}>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ display: "flex", paddingTop: { xs: "0 !important", sm: "inherith" } }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: { xs: 2, sm: 0 } }}>
                  <CheckIcon />
                  <Typography
                    color="text.secondary"
                    fontWeight={300}
                    variant="body2"
                    sx={{ ml: 2 }}
                  >
                    Treinamento para as equipes das concessionárias
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start", mt: { xs: 2, sm: 0 } }}>
                  <CheckIcon />
                  <Typography
                    color="text.secondary"
                    fontWeight={300}
                    variant="body2"
                    sx={{ ml: 2 }}
                  >
                    Instalação dos medidores ultrassônicos
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ display: "flex", mt: { xs: 2, sm: 4 } }}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <CheckIcon />
                  <Typography
                    color="text.secondary"
                    fontWeight={300}
                    variant="body2"
                    sx={{ ml: 2 }}
                  >
                    Substituição de outros medidores para os modelos série IoTA
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: "flex", mt: { xs: 2, sm: 4 } }}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <CheckIcon />
                  <Typography
                    color="text.secondary"
                    fontWeight={300}
                    variant="body2"
                    sx={{ ml: 2 }}
                  >
                    Acompanhamento durante a instalação para validar a sua execução, a comunicação
                    com os clientes e os testes de aceitação
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <BorderElementBottom
              sx={{
                position: "absolute",
                bottom: "-10px",
                right: "-10px",
                display: { xs: "none", sm: "block" },
              }}
            />
            <BorderElementBottomMobile
              sx={{
                position: "absolute",
                bottom: "-10px",
                right: "-10px",
                display: { xs: "block", sm: "none" },
              }}
            />
          </Grid>
        </Fragment>
      </ContentArea>
    </Box>
  );
};

export default FlowenPlatform;
