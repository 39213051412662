import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import CheckIconGreen from "./assets/images/check-icon-green";
import ContentArea from "./ContentArea";
import MedidorFlowen from "./assets/images/medidor-flowen.png";

const InfoSection03: React.FC = () => {
  return (
    <Box
      id="InfoSection03"
      sx={{ py: { xs: 10, sm: 10 }, position: "relative", backgroundColor: "background.default" }}
    >
      <ContentArea>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <Typography color="primary" fontWeight={700} variant="h2" component="div">
              Muito mais <br />{" "}
              <Box fontWeight="300" display="inline">
                do que medição
              </Box>
            </Typography>

            <Grid container sx={{ mt: 8 }} columnSpacing={10} rowSpacing={3}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIconGreen sx={{ mr: 2, alignSelf: "flex-start" }} />
                  <Typography color="text.secondary" fontWeight="300" variant="body2">
                    Tecnologia 100% nacional
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIconGreen sx={{ mr: 2, alignSelf: "flex-start" }} />
                  <Typography color="text.secondary" fontWeight="300" variant="body2">
                    Detecção de vazamentos
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIconGreen sx={{ mr: 2, alignSelf: "flex-start" }} />
                  <Typography color="text.secondary" fontWeight="300" variant="body2">
                    Modelo ultrassônico e de alta precisão
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIconGreen sx={{ mr: 2, alignSelf: "flex-start" }} />
                  <Typography color="text.secondary" fontWeight="300" variant="body2">
                    Informações de alarmes e eventos on-line
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIconGreen sx={{ mr: 2, alignSelf: "flex-start" }} />
                  <Typography color="text.secondary" fontWeight="300" variant="body2">
                    Medição remota com redes LPWAN
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIconGreen sx={{ mr: 2, alignSelf: "flex-start" }} />
                  <Typography color="text.secondary" fontWeight="300" variant="body2">
                    Parametrização remota
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIconGreen sx={{ mr: 2, alignSelf: "flex-start" }} />
                  <Typography color="text.secondary" fontWeight="300" variant="body2">
                    Válvula de corte/religamento integrada e gerenciável remotamente
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckIconGreen sx={{ mr: 2, alignSelf: "flex-start" }} />
                  <Typography color="text.secondary" fontWeight="300" variant="body2">
                    Bateria com duração de 12 anos
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box component="img" sx={{ marginLeft: "auto" }} src={`${MedidorFlowen}`}></Box>
          </Grid>
        </Grid>
      </ContentArea>
      <Box
        sx={{
          position: "absolute",
          background: "linear-gradient(90deg, #00BEF0 0%, #C8F0C8 100%)",
          right: "0",
          top: "630px",
          borderTopLeftRadius: "40px",
          borderBottomLeftRadius: "40px",
          width: "135px",
          height: "15px",
          display: { xs: "none", sm: "flex" },
        }}
      ></Box>
    </Box>
  );
};

export default InfoSection03;
