import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const DetailCTAMobile: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="172"
        height="81"
        viewBox="0 0 172 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M248.744 0.140155H328.076H328.096C333.016 0.140155 337 4.14461 337 9.0901C337 14.0356 333.016 18.04 328.096 18.04H248.763C247.09 18.04 245.526 18.9511 244.7 20.4127L215.666 71.4193C212.25 77.416 206.104 81 199.222 81C192.34 81 186.194 77.426 182.778 71.4193L158.386 28.5617C158.296 28.4016 158.057 27.9911 157.409 27.9911C156.753 27.9911 156.523 28.4007 156.434 28.5612L156.433 28.5617L132.161 71.2091C128.754 77.2058 122.609 80.7898 115.726 80.7898C108.844 80.7898 102.699 77.2158 99.2823 71.2091L70.2884 20.2625C69.4518 18.8109 67.898 17.8999 66.2247 17.8999H8.90433C3.98404 17.8999 0 13.8954 0 8.94995C0 4.00445 3.98404 0 8.90433 0H66.2247C74.2725 0 81.7525 4.36484 85.7465 11.3826L114.74 62.3293C114.83 62.4894 115.069 62.8999 115.716 62.8999C116.373 62.8999 116.602 62.4903 116.692 62.3297L116.693 62.3293L140.965 19.6819C144.372 13.6852 150.517 10.1012 157.399 10.1012C164.282 10.1012 170.427 13.6752 173.844 19.6819L198.236 62.5395C198.326 62.6997 198.565 63.1101 199.212 63.1101C199.85 63.1101 200.084 62.7143 200.18 62.5536L200.188 62.5395L229.222 11.5228C233.216 4.50501 240.696 0.140155 248.744 0.140155Z"
          fill="url(#paint0_linear_302_271)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_302_271"
            x1="-139.332"
            y1="40.495"
            x2="336.98"
            y2="40.495"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.1" stopColor="#C9FDDE" />
            <stop offset="1" stopColor="#00BAD8" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default DetailCTAMobile;
