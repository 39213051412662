import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const RecycleIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="36"
        height="48"
        viewBox="0 0 36 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.125 35.9805C0.50625 35.9805 0 35.4742 0 34.8555V12.3555C0 9.25047 2.52 6.73047 5.625 6.73047H29.25C29.8687 6.73047 30.375 7.23672 30.375 7.85547C30.375 8.47422 29.8687 8.98047 29.25 8.98047H5.625C3.76875 8.98047 2.25 10.4992 2.25 12.3555V34.8555C2.25 35.4742 1.74375 35.9805 1.125 35.9805Z"
          fill="#152253"
        />
        <path
          d="M23.6251 15.7303C23.3326 15.7303 23.0514 15.6178 22.8264 15.4041C22.3876 14.9653 22.3876 14.2566 22.8264 13.8178L28.7776 7.86656L22.8264 1.91531C22.3876 1.47656 22.3876 0.767812 22.8264 0.329062C23.2651 -0.109687 23.9739 -0.109687 24.4126 0.329062L31.1626 7.07906C31.6014 7.51781 31.6014 8.22656 31.1626 8.66531L24.4126 15.4153C24.1876 15.6403 23.9064 15.7416 23.6139 15.7416L23.6251 15.7303Z"
          fill="#152253"
        />
        <path
          d="M30.375 40.4805H6.75C6.13125 40.4805 5.625 39.9742 5.625 39.3555C5.625 38.7367 6.13125 38.2305 6.75 38.2305H30.375C32.2313 38.2305 33.75 36.7117 33.75 34.8555V12.3555C33.75 11.7367 34.2563 11.2305 34.875 11.2305C35.4937 11.2305 36 11.7367 36 12.3555V34.8555C36 37.9605 33.48 40.4805 30.375 40.4805Z"
          fill="#152253"
        />
        <path
          d="M12.3751 47.2303C12.0826 47.2303 11.8014 47.1178 11.5764 46.9041L4.82638 40.1541C4.38763 39.7153 4.38763 39.0066 4.82638 38.5678L11.5764 31.8178C12.0151 31.3791 12.7239 31.3791 13.1626 31.8178C13.6014 32.2566 13.6014 32.9653 13.1626 33.4041L7.21138 39.3553L13.1626 45.3066C13.6014 45.7453 13.6014 46.4541 13.1626 46.8928C12.9376 47.1178 12.6564 47.2191 12.3639 47.2191L12.3751 47.2303Z"
          fill="#152253"
        />
      </svg>
    </Box>
  );
};

export default RecycleIcon;
