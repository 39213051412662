import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const IntegrationIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="43" cy="43" r="43" fill="#CCF2FC" />
        <path
          d="M26.125 54.9803C25.5062 54.9803 25 54.4741 25 53.8553V31.3553C25 28.2503 27.52 25.7303 30.625 25.7303H54.25C54.8688 25.7303 55.375 26.2366 55.375 26.8553C55.375 27.4741 54.8688 27.9803 54.25 27.9803H30.625C28.7688 27.9803 27.25 29.4991 27.25 31.3553V53.8553C27.25 54.4741 26.7438 54.9803 26.125 54.9803Z"
          fill="#152253"
          stroke="#152253"
          strokeWidth="0.5"
          strokeLinejoin="round"
        />
        <path
          d="M48.6251 34.7303C48.3326 34.7303 48.0514 34.6178 47.8264 34.4041C47.3876 33.9653 47.3876 33.2566 47.8264 32.8178L53.7776 26.8666L47.8264 20.9153C47.3876 20.4766 47.3876 19.7678 47.8264 19.3291C48.2651 18.8903 48.9739 18.8903 49.4126 19.3291L56.1626 26.0791C56.6014 26.5178 56.6014 27.2266 56.1626 27.6653L49.4126 34.4153C49.1876 34.6403 48.9064 34.7416 48.6139 34.7416L48.6251 34.7303Z"
          fill="#152253"
          stroke="#152253"
          strokeWidth="0.5"
          strokeLinejoin="round"
        />
        <path
          d="M55.375 59.4803H31.75C31.1312 59.4803 30.625 58.9741 30.625 58.3553C30.625 57.7366 31.1312 57.2303 31.75 57.2303H55.375C57.2312 57.2303 58.75 55.7116 58.75 53.8553V31.3553C58.75 30.7366 59.2562 30.2303 59.875 30.2303C60.4938 30.2303 61 30.7366 61 31.3553V53.8553C61 56.9603 58.48 59.4803 55.375 59.4803Z"
          fill="#152253"
          stroke="#152253"
          strokeWidth="0.5"
          strokeLinejoin="round"
        />
        <path
          d="M37.3751 66.2303C37.0826 66.2303 36.8014 66.1178 36.5764 65.9041L29.8264 59.1541C29.3876 58.7153 29.3876 58.0066 29.8264 57.5678L36.5764 50.8178C37.0151 50.3791 37.7239 50.3791 38.1626 50.8178C38.6014 51.2566 38.6014 51.9653 38.1626 52.4041L32.2114 58.3553L38.1626 64.3066C38.6014 64.7453 38.6014 65.4541 38.1626 65.8928C37.9376 66.1178 37.6564 66.2191 37.3639 66.2191L37.3751 66.2303Z"
          fill="#152253"
          stroke="#152253"
          strokeWidth="0.5"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default IntegrationIcon;
