import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import DetailTopPage from "./assets/images/detail-top-page";
import ContentArea from "./ContentArea";
import BGWhoWeArea from "./assets/images/bg-we-are-flowen.png";
import LogoMeta from "./assets/images/logo-meta.png";
import LogoAyga from "./assets/images/logo-ayga.png";
import DetailTopPageMobile from "./assets/images/detail-top-page-mobile";
import LogoBlue from "./assets/images/logo-blue";

const WhoWeAre: React.FC = () => {
  return (
    <Box
      id="WhoWeAreSection"
      sx={{
        pt: { xs: 15, sm: 20 },
        pb: { xs: 10, sm: 20 },
        position: "relative",
        backgroundImage: `url(${BGWhoWeArea})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <DetailTopPage
        sx={{ position: "absolute", right: 0, top: 0, display: { xs: "none", sm: "block" } }}
      />
      <DetailTopPageMobile
        sx={{
          width: "100%",
          textAlign: "center",
          position: "absolute",
          top: 0,
          display: { xs: "block", sm: "none" },
        }}
      />

      <ContentArea
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h2" component="div" color="primary" fontWeight="300">
              We are{" "}
              <Box display="inline" fontWeight={700}>
                Flowen
              </Box>
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="text-secondary"
              fontWeight="300"
              sx={{ maxWidth: "495px", mt: 2 }}
            >
              A Flowen é uma empresa de{" "}
              <Box display="inline" fontWeight={700}>
                Smart Water{" "}
              </Box>
              que nasceu da união e expertise de duas grandes empresas - a{" "}
              <Box display="inline" fontWeight={700}>
                Meta,{" "}
              </Box>
              que possui mais de 30 anos no mercado de Transformação Digital e a{" "}
              <Box display="inline" fontWeight={700}>
                AYGA,{" "}
              </Box>
              especializada em soluções IoT e monitoramento e gestão de ativos.
            </Typography>

            <Typography
              variant="body2"
              component="div"
              color="text-secondary"
              fontWeight="700"
              sx={{ maxWidth: "495px", mt: 2 }}
            >
              Nosso propósito é otimizar a gestão da água,{" "}
              <Box display="inline" fontWeight={300}>
                recurso essencial para a vida que vem sendo desperdiçado pela falta de investimentos
                em tecnologia e infraestrutura de qualidade. Com o olhar voltado para o meio
                ambiente, buscando mais transparência com os consumidores e tecnologia para as
                concessionárias.
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                maxWidth: { xs: "215px", sm: "100%" },
                margin: { xs: "auto", sm: "initial" },
                paddingTop: { xs: "3rem", sm: 0 },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Link href="https://www.ayga.com.br/" target="_blank">
                  <Box component="img" src={`${LogoAyga}`}></Box>
                </Link>
                <Link href="https://www.meta.com.br/" target="_blank">
                  <Box component="img" src={`${LogoMeta}`} sx={{ ml: 4 }}></Box>
                </Link>
              </Box>

              <Divider
                sx={{ my: 4, color: "#d4d4d4", width: "340px", borderBottomWidth: "medium" }}
              />

              <LogoBlue />
            </Box>
          </Grid>
        </Grid>
      </ContentArea>
    </Box>
  );
};

export default WhoWeAre;
