const IconIvoire: React.FC = () => {
  return (
    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.62214 5.66584V0H5.71786C2.56154 0 0 2.53824 0 5.66584L0.00249906 16.9975L4.76072 17V14.1671C4.76072 14.1151 4.8032 14.073 4.85568 14.073C4.88067 14.073 4.90316 14.0829 4.92065 14.0977L4.92315 14.1002L7.61714 16.9975H9.52393V14.1671C9.52393 14.1151 9.56641 14.073 9.61889 14.073C9.64638 14.073 9.66887 14.0829 9.68637 14.1002L12.3829 17H14.2896L14.2946 11.3342H13.3425C10.1837 11.3342 7.62214 8.79592 7.62214 5.66584Z"
        fill="#454545"
      />
      <path
        d="M19.0554 13.2871V5.66764C19.0554 2.53765 16.4964 0.00195312 13.3376 0.00195312H8.57184L8.56934 5.45964C8.56934 8.1761 10.786 10.3874 13.5275 10.3874H18.1008C18.1533 10.3874 18.1957 10.4295 18.1957 10.4815C18.1957 10.5063 18.1857 10.531 18.1682 10.5484L15.2493 13.2376L15.2468 15.0576C15.2468 15.075 15.2493 15.0923 15.2493 15.1096C15.2493 16.1497 16.1015 16.9941 17.1511 16.9941C17.1786 16.9941 20.0026 16.999 20.0026 16.999V14.1538C19.5003 14.1538 19.0929 13.7725 19.0554 13.2871ZM14.787 6.44024C14.3572 6.44024 14.0073 6.09356 14.0073 5.66764C14.0073 5.24173 14.3572 4.89505 14.787 4.89505C15.2169 4.89505 15.5667 5.24173 15.5667 5.66764C15.5667 6.09356 15.2169 6.44024 14.787 6.44024Z"
        fill="#454545"
      />
    </svg>
  );
};

export default IconIvoire;
