import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const DetailCTA: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="608"
        height="298"
        viewBox="0 0 608 298"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M918.211 0.515632H1211.06H1211.13C1229.29 0.515632 1244 15.2481 1244 33.4426C1244 51.6371 1229.29 66.3695 1211.13 66.3695H918.284C912.107 66.3695 906.335 69.7212 903.283 75.0985L796.109 262.753C783.498 284.814 760.813 298 735.407 298C710.001 298 687.316 284.851 674.705 262.753L584.663 105.079C584.333 104.49 583.45 102.98 581.06 102.98C578.636 102.98 577.79 104.487 577.458 105.077L577.457 105.079L487.857 261.979C475.283 284.041 452.598 297.227 427.192 297.227C401.786 297.227 379.101 284.078 366.49 261.979L259.462 74.546C256.374 69.2055 250.638 65.8539 244.462 65.8539H32.8694C14.7067 65.8539 0 51.1215 0 32.927C0 14.7324 14.7067 0 32.8694 0H244.462C274.169 0 301.781 16.0583 316.524 41.8769L423.552 229.31C423.883 229.899 424.765 231.409 427.155 231.409C429.579 231.409 430.426 229.902 430.757 229.312L430.758 229.31L520.359 72.4098C532.933 50.348 555.618 37.1625 581.024 37.1625C606.429 37.1625 629.114 50.3112 641.725 72.4098L731.767 230.084C732.098 230.673 732.98 232.183 735.37 232.183C737.724 232.183 738.591 230.727 738.942 230.135L738.973 230.084L846.148 42.3925C860.891 16.574 888.503 0.515632 918.211 0.515632Z"
          fill="url(#paint0_linear_231_191)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_231_191"
            x1="-514.329"
            y1="148.981"
            x2="1243.93"
            y2="148.981"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.1" stopColor="#C9FDDE" />
            <stop offset="1" stopColor="#00BAD8" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default DetailCTA;
