import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const AppIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="43" cy="43" r="43" fill="#B6D9C3" />
        <path
          d="M27 65.8C27 68 28.8 69.8 31 69.8H55C57.2 69.8 59 68 59 65.8V59.8H27V65.8Z"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59 59.8099H27V29.7999C27 29.7999 27.02 29.7899 27.02 29.7999V59.7899H59V29.7999C59 29.7999 59.02 29.7899 59.02 29.7999V59.7999L59 59.8099Z"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45 64.8101H41"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.35 21.71C51.32 18.68 47.3 17.02 43.01 17.02C38.72 17.02 34.69 18.69 31.67 21.71C31.67 21.71 31.67 21.71 31.66 21.71C31.65 21.71 31.66 21.71 31.66 21.7C34.69 18.67 38.72 17 43.01 17C47.3 17 51.34 18.67 54.37 21.7C54.37 21.7 54.37 21.7 54.37 21.71H54.35Z"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.46 25.5699C34.46 25.5699 34.46 25.5399 34.47 25.5299C39.17 20.8299 46.81 20.8299 51.51 25.5299C51.53 25.5499 51.53 25.5799 51.51 25.5999C51.49 25.6199 51.46 25.6199 51.44 25.5999C46.78 20.9399 39.2 20.9399 34.54 25.5999C34.52 25.6199 34.49 25.6199 34.47 25.5999C34.47 25.5999 34.46 25.5799 34.46 25.5599V25.5699Z"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.65 29.75C37.65 29.75 37.65 29.72 37.66 29.71C39.09 28.28 40.98 27.5 43 27.5C45.02 27.5 46.91 28.29 48.34 29.71C48.36 29.73 48.36 29.76 48.34 29.78C48.32 29.8 48.29 29.8 48.27 29.78C46.86 28.37 44.99 27.6 43 27.6C41.01 27.6 39.14 28.37 37.73 29.78C37.71 29.8 37.68 29.8 37.66 29.78C37.66 29.78 37.65 29.76 37.65 29.74V29.75Z"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default AppIcon;
