import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const CheckIconGreen: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 28C22.1797 28 28 22.1797 28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15C2 22.1797 7.8203 28 15 28Z"
          stroke="#96C8AA"
          strokeWidth="2.16667"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.64709 15L13.4706 18.8236L21.1177 11.1765"
          stroke="#96C8AA"
          strokeWidth="2.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default CheckIconGreen;
