import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const BorderElementBottomMobile: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="156"
        height="67"
        viewBox="0 0 156 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 60.9993L108.865 60.9995C132.137 60.9995 151 42.0722 151 18.73L151 4.99954"
          stroke="url(#paint0_linear_302_71)"
          strokeWidth="10"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <circle cx="6" cy="61" r="6" transform="rotate(-180 6 61)" fill="#E0E8F3" />
        <defs>
          <linearGradient
            id="paint0_linear_302_71"
            x1="151"
            y1="33.0023"
            x2="5.5"
            y2="33.0023"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00BEF0" />
            <stop offset="1" stopColor="#C8F0C8" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default BorderElementBottomMobile;
