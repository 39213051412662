import { Box, Grid, keyframes, Link, Typography } from "@mui/material";
import ContentArea from "./ContentArea";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const blink = keyframes`
  from {
    opacity: .2
  }
  to {
    opacity: 1
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const BannerSection: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: "#01879C", position: "relative" }} id="BannerSection">
      <Box
        component="video"
        autoPlay
        muted
        sx={{
          position: "absolute",
          right: 0,
          minWidth: "100%",
          minHeight: "100%",
          "@media (max-height: 600px) and (width: 1366px)": {
            minHeight: "115%",
          },
          zIndex: 9,
          maxWidth: "100vw",
          maxHeight: "100vh",
          objectFit: "cover",
        }}
      >
        <source src="/flow-banner.mp4" type="video/mp4" />
      </Box>

      <ContentArea sx={{ zIndex: 10, position: "relative", height: "100vh" }}>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          sx={{
            minHeight: `100%`,
            animation: `${fadeIn} 3s cubic-bezier(.5, 0, 1, 1) alternate`,
          }}
        >
          <Grid
            item
            xs={12}
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{ marginTop: { xs: 20, sm: "auto" } }}
          >
            <Typography
              variant="h3"
              color="primary.contrastText"
              fontWeight={300}
              sx={{ maxWidth: { xs: "200px", sm: "initial" } }}
            >
              Solução completa e integrada para a
            </Typography>
            <Typography
              variant="h1"
              color="primary.contrastText"
              fontWeight={700}
              sx={{ maxWidth: { xs: "330px", sm: "initial" } }}
            >
              gestão inteligente da água
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center" alignSelf="flex-end" sx={{ marginBottom: "-1px" }}>
            <Typography
              variant="body1"
              color="primary.contrastText"
              fontWeight={300}
              sx={{ letterSpacing: `0.4em`, textTransform: "uppercase", mb: 2 }}
            >
              Explore o conteúdo
            </Typography>
            <Link
              href="#InfoSection01"
              sx={{
                backgroundColor: "background.default",
                borderTopLeftRadius: "80px",
                borderTopRightRadius: "80px",
                pt: 2,
                width: "50px",
                height: "45px",
                display: "block",
                margin: "auto",
              }}
            >
              <KeyboardArrowDownIcon
                sx={{
                  color: "text.primary",
                  animation: `${blink} 1s cubic-bezier(.5, 0, 1, 1) infinite alternate`,
                }}
              />
            </Link>
          </Grid>
        </Grid>
      </ContentArea>
    </Box>
  );
};

export default BannerSection;
