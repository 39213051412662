import { GlobalStyles as MUIGlobalStyles, Theme } from "@mui/material";

const GlobalStyles = (props: { theme: Theme }) => {
  const globalStyles = (
    <MUIGlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
        },
        webkitAppearance: "none",
        ".MuiFormHelperText-root.Mui-error": {
          marginTop: "5px",
        },
        html: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
          scrollBehavior: "smooth",
        },
        body: {
          margin: 0,
          padding: 0,
          width: "100%",
          backgroundColor: "rgba(237, 237, 237, 0.42)",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        img: {
          display: "block",
          maxWidth: "100%",
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  );

  return globalStyles;
};

export default GlobalStyles;
