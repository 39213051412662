const LogoBlue: React.FC = () => {
  return (
    <svg
      width="193"
      height="57"
      viewBox="0 0 193 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.9487 44.4993C42.3856 44.4993 36.2381 38.3518 36.2381 30.7887C36.2381 23.2256 42.3856 17.0781 49.9487 17.0781C57.5118 17.0781 63.6593 23.2256 63.6593 30.7887C63.6593 38.3518 57.5118 44.4993 49.9487 44.4993ZM49.9487 23.2256C45.7829 23.2256 42.3856 26.623 42.3856 30.7887C42.3856 34.9545 45.7829 38.3518 49.9487 38.3518C54.1144 38.3518 57.5118 34.9545 57.5118 30.7887C57.5118 26.623 54.1144 23.2256 49.9487 23.2256Z"
        fill="#05BAEE"
      />
      <path
        d="M8.00798 56.9964C6.30932 56.9964 4.9342 55.6213 4.9342 53.9226V26.8654C4.9342 19.3023 11.0817 13.1548 18.6448 13.1548C20.3435 13.1548 21.7186 14.5299 21.7186 16.2286C21.7186 17.9272 20.3435 19.3023 18.6448 19.3023C14.4791 19.3023 11.0817 22.6996 11.0817 26.8654V53.9226C11.0817 55.6213 9.70664 56.9964 8.00798 56.9964Z"
        fill="#00BEF0"
      />
      <path
        d="M150.129 44.661C146.246 44.661 142.526 43.0028 139.937 40.0908C137.349 37.1789 136.095 33.2962 136.54 29.4136C137.227 23.1043 142.323 18.0487 148.592 17.3612C152.475 16.9163 156.357 18.1701 159.269 20.7585C162.181 23.3469 163.84 27.0678 163.84 30.9505C163.84 32.6491 162.465 34.0242 160.766 34.0242C159.067 34.0242 157.692 32.6491 157.692 30.9505C157.692 28.8069 156.762 26.7442 155.185 25.3287C153.567 23.8727 151.464 23.2256 149.28 23.4683C145.801 23.8323 143.051 26.6229 142.647 30.1011C142.404 32.2851 143.051 34.3882 144.507 36.006C145.923 37.6238 147.986 38.5135 150.129 38.5135C151.828 38.5135 153.203 39.8886 153.203 41.5873C153.203 43.2859 151.828 44.661 150.129 44.661Z"
        fill="#00BEF0"
      />
      <path
        d="M30.0096 44.4992C28.3109 44.4992 26.9359 43.1241 26.9359 41.385V3.7314C26.9359 2.03274 28.3109 0.617188 30.0096 0.617188C31.7083 0.617188 33.0834 1.9923 33.0834 3.7314V41.385C33.0834 43.0837 31.7083 44.4992 30.0096 44.4992Z"
        fill="#00BEF0"
      />
      <path
        d="M189.886 44.6607C188.187 44.6607 186.812 43.2856 186.812 41.5869V26.5416C186.812 24.3981 185.437 22.5377 183.779 22.5377H175.285C174.477 22.5377 173.829 23.1848 173.829 23.9937V41.5869C173.829 43.2856 172.454 44.6607 170.756 44.6607C169.057 44.6607 167.682 43.2856 167.682 41.5869V23.9937C167.682 19.8279 171.079 16.3901 175.285 16.3901H183.819C188.875 16.3901 193 20.9199 193 26.5416V41.5869C192.96 43.2856 191.584 44.6607 189.886 44.6607Z"
        fill="#00BEF0"
      />
      <path
        d="M23.7408 31.274C23.7408 32.9727 22.4466 34.3478 20.8288 34.3883L2.99288 34.5905H2.95245C1.33468 34.5905 0 33.2558 0 31.5572C0 29.8585 1.29422 28.4834 2.91199 28.4429L20.7479 28.2407H20.7884C22.4061 28.2407 23.7003 29.5754 23.7408 31.274ZM160.766 28.2812H132.819C130.028 28.2812 127.48 29.7776 126.105 32.2043L116.075 49.7166C116.035 49.7571 115.954 49.9188 115.751 49.9188C115.509 49.9188 115.428 49.7571 115.428 49.7166L107.016 34.9949C105.843 32.9323 103.699 31.7189 101.353 31.7189C98.9671 31.7189 96.864 32.9323 95.6911 34.9949L87.3192 49.6357C87.2787 49.6762 87.1978 49.8379 86.9956 49.8379C86.7529 49.8379 86.6721 49.6762 86.6721 49.6357L76.6014 32.1638C75.2263 29.7372 72.6379 28.2407 69.8877 28.2407H50.1104C48.4118 28.2407 47.0367 29.6158 47.0367 31.3145C47.0367 33.0131 48.4118 34.3883 50.1104 34.3883H69.8877C70.4539 34.3883 71.0201 34.7118 71.3032 35.1971L81.293 52.669C82.4658 54.7317 84.6094 55.945 86.9551 55.945C89.3414 55.945 91.4445 54.7317 92.6173 52.669L100.989 38.0282C101.03 37.9878 101.111 37.826 101.313 37.826C101.556 37.826 101.636 37.9878 101.636 38.0282L110.049 52.7499C111.222 54.8126 113.365 56.0259 115.711 56.0259C118.097 56.0259 120.2 54.8126 121.373 52.7499L131.403 35.2376C131.687 34.7522 132.212 34.4287 132.819 34.4287H160.766C162.465 34.4287 163.84 33.0536 163.84 31.3549C163.84 29.6563 162.465 28.2812 160.766 28.2812Z"
        fill="url(#paint0_linear_251_1992)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_251_1992"
          x1="144.407"
          y1="42.1302"
          x2="31.3621"
          y2="42.1302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BEF0" />
          <stop offset="1" stopColor="#CBE3CD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoBlue;
