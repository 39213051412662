import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const Bulb: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="84"
        height="88"
        viewBox="0 0 84 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_111_1955)">
          <path d="M52 68H32V72H52V68Z" fill="#00BEF0" />
          <path
            d="M42 10C40.8933 10 40 9.10667 40 8V2C40 0.893333 40.8933 0 42 0C43.1067 0 44 0.893333 44 2V8C44 9.10667 43.1067 10 42 10Z"
            fill="#00BEF0"
          />
          <path
            d="M18 20C17.4933 20 16.9733 19.8 16.5866 19.4134L12.5866 15.4134C11.8 14.6267 11.8 13.36 12.5866 12.5867C13.3733 11.8 14.64 11.8 15.4133 12.5867L19.4133 16.5867C20.2 17.3734 20.2 18.64 19.4133 19.4134C19.0266 19.8 18.5066 20 18 20Z"
            fill="#00BEF0"
          />
          <path
            d="M8 44H2C0.893333 44 0 43.1067 0 42C0 40.8933 0.893333 40 2 40H8C9.10667 40 10 40.8933 10 42C10 43.1067 9.10667 44 8 44Z"
            fill="#00BEF0"
          />
          <path
            d="M14 72C13.4933 72 12.9733 71.8 12.5866 71.4134C11.8 70.6267 11.8 69.36 12.5866 68.5867L16.5866 64.5867C17.3733 63.8 18.64 63.8 19.4133 64.5867C20.2 65.3734 20.2 66.64 19.4133 67.4134L15.4133 71.4134C15.0266 71.8 14.5066 72 14 72Z"
            fill="#00BEF0"
          />
          <path
            d="M66 20C65.4933 20 64.9733 19.8 64.5866 19.4134C63.8 18.6267 63.8 17.36 64.5866 16.5867L68.5866 12.5867C69.3733 11.8 70.64 11.8 71.4133 12.5867C72.2 13.3734 72.2 14.64 71.4133 15.4134L67.4133 19.4134C67.0266 19.8 66.5066 20 66 20Z"
            fill="#00BEF0"
          />
          <path
            d="M82 44H76C74.8933 44 74 43.1067 74 42C74 40.8933 74.8933 40 76 40H82C83.1067 40 84 40.8933 84 42C84 43.1067 83.1067 44 82 44Z"
            fill="#00BEF0"
          />
          <path
            d="M70 72C69.4933 72 68.9733 71.8 68.5866 71.4134L64.5866 67.4134C63.8 66.6267 63.8 65.36 64.5866 64.5867C65.3733 63.8 66.64 63.8 67.4133 64.5867L71.4133 68.5867C72.2 69.3734 72.2 70.64 71.4133 71.4134C71.0266 71.8 70.5066 72 70 72Z"
            fill="#00BEF0"
          />
          <path
            d="M48 88H36C35.3333 88 34.7067 87.6667 34.3333 87.1067L30.3333 81.04C30.12 80.72 30 80.3333 30 79.9333V69.8933C30 67.9733 27.7067 65.7467 25.04 63.1733C20.3867 58.6667 14 52.4933 14 42C14 25.7733 25.7733 14 42 14C58.2267 14 70 25.7733 70 42C70 52.4933 63.6267 58.6667 58.96 63.1733C56.2933 65.7467 54 67.9733 54 69.8933V79.9333C54 80.32 53.88 80.7067 53.6667 81.04L49.6667 87.1067C49.2933 87.6667 48.6667 88 48 88ZM37.08 84H46.9333L50.0133 79.3333V69.8933C50.0133 66.28 52.88 63.5067 56.1867 60.2933C60.7867 55.84 66.0133 50.7867 66.0133 41.9867C66.0133 27.8533 56.1467 17.9867 42.0133 17.9867C27.88 17.9867 18.0133 27.8533 18.0133 41.9867C18.0133 50.7867 23.2267 55.8267 27.84 60.2933C31.16 63.5067 34.0133 66.28 34.0133 69.8933V79.3333L37.0933 84H37.08Z"
            fill="#00BEF0"
          />
          <path
            d="M42 58C36.48 58 32 54.4133 32 50C32 48.8933 32.8933 48 34 48C35.1067 48 36 48.8933 36 50C36 52.1733 38.7467 54 42 54C45.2533 54 48 52.1733 48 50C48 48.9067 48 46 42 46C32.9733 46 32 40.4 32 38C32 33.5867 36.48 30 42 30C47.52 30 52 33.5867 52 38C52 39.1067 51.1067 40 50 40C48.8933 40 48 39.1067 48 38C48 35.8267 45.2533 34 42 34C38.7467 34 36 35.8267 36 38C36 39.0933 36 42 42 42C51.0267 42 52 47.6 52 50C52 54.4133 47.52 58 42 58Z"
            fill="#00BEF0"
          />
          <path
            d="M42 34C40.8933 34 40 33.1067 40 32V28C40 26.8933 40.8933 26 42 26C43.1067 26 44 26.8933 44 28V32C44 33.1067 43.1067 34 42 34Z"
            fill="#00BEF0"
          />
          <path
            d="M42 62C40.8933 62 40 61.1067 40 60V56C40 54.8933 40.8933 54 42 54C43.1067 54 44 54.8933 44 56V60C44 61.1067 43.1067 62 42 62Z"
            fill="#00BEF0"
          />
        </g>
        <defs>
          <clipPath id="clip0_111_1955">
            <rect width="84" height="88" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};

export default Bulb;
