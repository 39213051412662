import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const BorderElementTopMobile: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="156"
        height="67"
        viewBox="0 0 156 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M150.5 6.00068L47.1348 6.00049C23.863 6.00049 5 24.9278 5 48.27L5 62.0005"
          stroke="#9578F3"
          strokeWidth="10"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <circle cx="150" cy="6" r="6" fill="#E0E8F3" />
      </svg>
    </Box>
  );
};

export default BorderElementTopMobile;
