const IconLinkedIn: React.FC = () => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_132_113)">
        <path
          d="M22.4589 21.7958V21.7949H22.4644V13.9586C22.4644 10.125 21.6266 7.17188 17.0769 7.17188C14.8897 7.17188 13.4219 8.35418 12.8227 9.47506H12.7595V7.52977H8.44568V21.7949H12.9375V14.7313C12.9375 12.8715 13.2954 11.0731 15.6335 11.0731C17.9373 11.0731 17.9716 13.1956 17.9716 14.8506V21.7958H22.4589Z"
          fill="white"
        />
        <path d="M1.13196 7.53027H5.62922V21.7954H1.13196V7.53027Z" fill="white" />
        <path
          d="M3.37815 0.428711C1.94022 0.428711 0.773438 1.57807 0.773438 2.99451C0.773438 4.41096 1.94022 5.58436 3.37815 5.58436C4.81607 5.58436 5.98286 4.41096 5.98286 2.99451C5.98195 1.57807 4.81517 0.428711 3.37815 0.428711V0.428711Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_132_113">
          <rect
            width="21.691"
            height="21.367"
            fill="white"
            transform="translate(0.773438 0.428711)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconLinkedIn;
