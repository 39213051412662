import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const Cog: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="82"
        height="82"
        viewBox="0 0 82 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8742 33.1268C14.4655 31.0697 15.2785 29.0743 16.3378 27.1773L11.2629 20.9569C9.871 19.2447 9.99417 16.7443 11.5585 15.1799L15.1922 11.5462C16.7566 9.98186 19.2447 9.85868 20.9692 11.2506L27.1897 16.3255C29.0619 15.2785 31.0451 14.4409 33.1391 13.8619L33.9521 5.88007C34.1738 3.6752 36.0337 2 38.2386 2H43.3874C45.6046 2 47.4522 3.6752 47.674 5.88007L48.4869 13.8619C50.544 14.4532 52.5394 15.2661 54.4364 16.3255L60.6568 11.2506C62.3689 9.85868 64.8694 9.98186 66.4338 11.5462L70.0675 15.1799C71.6318 16.7443 71.755 19.2324 70.3631 20.9569L65.2882 27.1773C66.3229 29.0619 67.1605 31.0451 67.7394 33.1268L75.7213 33.9397C77.9262 34.1615 79.6014 36.0214 79.6014 38.2263V43.3751C79.6014 45.5923 77.9262 47.4399 75.7213 47.6616L67.7394 48.4746C67.1482 50.544 66.3352 52.5394 65.2759 54.424L70.3508 60.6445C71.7427 62.3566 71.6195 64.8571 70.0552 66.4215L66.4215 70.0552C64.8571 71.6195 62.3689 71.7427 60.6445 70.3508L54.424 65.2759C52.5518 66.3229 50.5686 67.1605 48.4746 67.7394L47.6616 75.7213C47.4399 77.9262 45.5799 79.6014 43.3751 79.6014H38.2263C36.0091 79.6014 34.1615 77.9262 33.9397 75.7213L33.1268 67.7394C31.0697 67.1482 29.0743 66.3352 27.1773 65.2759L20.9569 70.3508C19.2448 71.7427 16.7443 71.6195 15.1799 70.0552L11.5462 66.4215C9.98185 64.8571 9.85868 62.3689 11.2506 60.6445L16.3255 54.424C15.2908 52.5394 14.4532 50.5563 13.8619 48.4746L5.88007 47.6616C3.6752 47.4399 2 45.5799 2 43.3751V38.2263C2 36.0091 3.6752 34.1615 5.88007 33.9397L13.8619 33.1268H13.8742Z"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinejoin="round"
        />
        <path
          d="M40.8253 53.7466C47.9683 53.7466 53.7589 47.9561 53.7589 40.8131C53.7589 33.6701 47.9683 27.8795 40.8253 27.8795C33.6823 27.8795 27.8918 33.6701 27.8918 40.8131C27.8918 47.9561 33.6823 53.7466 40.8253 53.7466Z"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default Cog;
