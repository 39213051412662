import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const DetailSidePage: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="198"
        height="255"
        viewBox="0 0 198 255"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M164.743 117.072C179.671 117.072 193.135 123.082 202.952 132.899C212.769 142.716 218.779 156.18 218.779 171.108L218.779 246"
          stroke="#96C8AA"
          strokeWidth="16.4667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M218.779 63.036C218.779 48.102 212.769 34.6437 202.952 24.827C193.135 15.0103 179.671 9 164.743 9"
          stroke="#A690F4"
          strokeWidth="16.4667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M110.707 63.036C110.707 48.102 116.717 34.6437 126.534 24.827C136.351 15.0103 149.815 9 164.743 9"
          stroke="#A690F4"
          strokeWidth="16.4667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00004 117.072L164.743 117.072"
          stroke="#50C8F0"
          strokeWidth="16.4667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M164.743 117.072C149.809 117.072 136.351 111.062 126.534 101.245C116.717 91.4284 110.707 77.9637 110.707 63.036"
          stroke="#50C8F0"
          strokeWidth="16.4667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M164.743 125.635C160.012 125.635 156.18 121.803 156.18 117.072C156.18 112.341 160.012 108.509 164.743 108.509C169.474 108.509 173.306 112.341 173.306 117.072C173.306 121.803 169.474 125.635 164.743 125.635Z"
          fill="#C8F0C8"
        />
        <path
          d="M110.707 71.5987C105.976 71.5987 102.144 67.767 102.144 63.036C102.144 58.305 105.976 54.4734 110.707 54.4734C115.438 54.4734 119.27 58.305 119.27 63.036C119.27 67.767 115.438 71.5987 110.707 71.5987Z"
          fill="#C8F0C8"
        />
      </svg>
    </Box>
  );
};

export default DetailSidePage;
