import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const DetailPage02: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="149"
        height="486"
        viewBox="0 0 149 486"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-166.749 165.667L-166.749 287.128C-166.749 324.571 -136.394 354.926 -98.951 354.926L49.9774 354.926"
          stroke="#F4F4F4"
          strokeWidth="16.4667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M111.531 286.425L51.0794 286.425C13.3708 286.425 -17.2002 316.996 -17.2002 354.705L-17.2002 389.177"
          stroke="#EDEDED"
          strokeWidth="12.6667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M49.9773 354.92L49.9773 76.792C49.9773 39.3493 80.333 9 117.769 9L139.79 9"
          stroke="#C8F0C8"
          strokeWidth="16.4667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M-201 271.231L-201 287.128C-201 315.299 -189.543 340.854 -171.113 359.284C-152.683 377.714 -127.128 389.171 -98.9573 389.171L111.531 389.171"
          stroke="#EDEDED"
          strokeWidth="16.4667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.82409 165.782C9.82409 150.848 3.81376 137.389 -6.0029 127.573C-15.8196 117.756 -29.2842 111.746 -44.2119 111.746"
          stroke="#D9D9D9"
          strokeWidth="16.4667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M111.531 219.818L-44.2119 219.818"
          stroke="#50C8F0"
          strokeWidth="16.4667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M111.531 252.175L49.9773 252.175"
          stroke="#96C8AA"
          strokeWidth="16.4667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M9.82415 174.344C14.5552 174.344 18.3868 170.513 18.3868 165.782C18.3868 161.051 14.5552 157.219 9.82415 157.219C5.09315 157.219 1.26147 161.051 1.26147 165.782C1.26147 170.513 5.09315 174.344 9.82415 174.344Z"
          fill="#C8F0C8"
        />
      </svg>
    </Box>
  );
};

export default DetailPage02;
