import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const FlowenAdvanceIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32" cy="32" r="32" fill="#96C8AA" />
        <path
          d="M17.7657 16.5625V44.2004H49"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7476 24.9393V39.6075"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.6843 29.5825V39.6075"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.621 23.5172V39.6075"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.5577 33.1653V39.6075"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default FlowenAdvanceIcon;
