import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import AnimatedCircle from "./AnimatedCircle";
import ContentArea from "./ContentArea";

const InfoSection04: React.FC = () => {
  return (
    <Box
      id="InfoSection04"
      sx={{
        py: { xs: 10, sm: 15 },
        position: "relative",
        backgroundColor: "rgba(150, 200, 170, 0.15)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          background: "linear-gradient(90deg, #00BEF0 0%, #C8F0C8 100%)",
          left: "-70px",
          "@media (max-width: 1366px)": {
            left: "-100px",
          },
          top: "130px",
          borderTopRightRadius: "40px",
          borderBottomRightRadius: "40px",
          width: "135px",
          height: "15px",
          display: { xs: "none", sm: "flex" },
        }}
      ></Box>
      <ContentArea>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="subtitle1"
              fontWeight={700}
              color="primary"
              sx={{ maxWidth: "310px" }}
            >
              Por que é importante{" "}
              <Box fontWeight={300} display="inline">
                repensar a gestão?
              </Box>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", sm: "center" },
              flexDirection: "column",
              justifyContent: "flex-start",
              mt: { xs: 3, sm: 0 },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <AnimatedCircle />
              <Typography
                component="div"
                color="text.primary"
                fontWeight={300}
                sx={{ maxWidth: "285px", ml: 2 }}
                variant="h6"
              >
                Cerca de{" "}
                <Box display="inline" fontWeight={700}>
                  40% da água tratada
                </Box>{" "}
                no Brasil é desperdiçada
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start", my: 3 }}>
              <AnimatedCircle />
              <Typography
                component="div"
                color="text.primary"
                fontWeight={700}
                sx={{ maxWidth: "285px", ml: 2 }}
                variant="h6"
              >
                80% das manutenções{" "}
                <Box display="inline" fontWeight={300}>
                  são feitas de forma reativa
                </Box>{" "}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <AnimatedCircle />
              <Typography
                component="div"
                color="text.primary"
                fontWeight={700}
                sx={{ maxWidth: "285px", ml: 2 }}
                variant="h6"
              >
                16% da população{" "}
                <Box display="inline" fontWeight={300}>
                  não tem água tratada em casa
                </Box>{" "}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", sm: "flex-start" },
              flexDirection: "column",
              justifyContent: "flex-start",
              mt: { xs: 3, sm: 0 },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <AnimatedCircle />
              <Typography
                component="div"
                color="text.primary"
                fontWeight={300}
                sx={{ maxWidth: "285px", ml: 2 }}
                variant="h6"
              >
                Medidores mecânicos{" "}
                <Box display="inline" fontWeight={700}>
                  têm vida útil curta
                </Box>{" "}
                e passível de erro
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start", mt: { xs: 3, sm: 3 } }}>
              <AnimatedCircle />
              <Typography
                component="div"
                color="text.primary"
                fontWeight={700}
                sx={{ maxWidth: "285px", ml: 2 }}
                variant="h6"
              >
                Os consumidores não sabem{" "}
                <Box display="inline" fontWeight={300}>
                  hoje qual é o seu consumo diário
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ContentArea>
    </Box>
  );
};

export default InfoSection04;
