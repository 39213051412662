export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ xs, sm, md, lg }: any) {
  return {
    "@media (min-width:0px)": {
      fontSize: pxToRem(xs),
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = "Noto Sans";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 300,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    lineHeight: 1.4,
    fontSize: pxToRem(64),
    ...responsiveFontSizes({ xs: 48, sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    lineHeight: 1.4,
    fontSize: pxToRem(56),
    ...responsiveFontSizes({ xs: 40, sm: 44, md: 50, lg: 56 }),
  },
  h3: {
    lineHeight: 1.4,
    fontSize: pxToRem(48),
    ...responsiveFontSizes({ xs: 24, sm: 36, md: 42, lg: 48 }),
  },
  h4: {
    lineHeight: 1.4,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 24, md: 28, lg: 32 }),
  },
  h5: {
    lineHeight: 1.4,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ xs: 24, sm: 22, md: 22, lg: 24 }),
  },
  h6: {
    lineHeight: 1.6,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ xs: 18, sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    lineHeight: 1.4,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ xs: 32, sm: 32, md: 40, lg: 40 }),
  },
  subtitle2: {
    lineHeight: 1.4,
    fontSize: pxToRem(30),
  },
  body1: {
    fontWeight: 300,
    lineHeight: 1.4,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ xs: 12, sm: 12, md: 14, lg: 16 }),
  },
  body2: {
    lineHeight: 1.4,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ xs: 20, sm: 20, md: 24, lg: 24 }),
  },
  caption: {
    lineHeight: 1.2,
    fontSize: pxToRem(14),
  },
};

export default typography;
