import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const ParamConfig: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="52"
        height="55"
        viewBox="0 0 52 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.26227 32.4716L8.5224 33.7152C8.9623 35.3141 9.60524 36.8284 10.4343 38.2157L6.99121 43.5876C8.34475 45.3388 9.9267 46.8953 11.7032 48.2066L17.0074 44.662C18.4117 45.4657 19.9429 46.0832 21.5502 46.4893L22.9207 52.724C24.0035 52.8594 25.1033 52.9355 26.22 52.9271C27.3451 52.9101 28.4449 52.8171 29.5192 52.6648L30.7628 46.4047C32.3617 45.9648 33.8759 45.3218 35.2633 44.4843L40.6352 47.9274C42.3779 46.5739 43.9345 44.9919 45.2542 43.2154L41.7096 37.9112C42.5132 36.5069 43.1308 34.9757 43.5368 33.3684L49.7716 31.9979"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.0332 28.8255C36.0839 34.3666 31.6426 38.9094 26.0931 38.9602C20.552 39.0194 16.0092 34.5696 15.9584 29.0286"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 26C2 12.7437 12.7437 2 26 2C39.2563 2 50 12.7437 50 26"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 26L34.0874 7.77795"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 10.8319V7.77795"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2732 15.2732L13.116 13.116"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.7268 15.2732L38.884 13.116"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.7117 14.3426C39.0532 17.1259 41.1766 21.3134 41.1766 26H44.2221"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.77795 26H10.8234C10.8234 17.6165 17.6165 10.8234 26 10.8234C26.6599 10.8234 27.3113 10.8657 27.9457 10.9503"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 28.6563C27.467 28.6563 28.6563 27.467 28.6563 26C28.6563 24.5329 27.467 23.3437 26 23.3437C24.5329 23.3437 23.3436 24.5329 23.3436 26C23.3436 27.467 24.5329 28.6563 26 28.6563Z"
          fill="#F5F5F5"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default ParamConfig;
