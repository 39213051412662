import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const PhoneIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="35"
        height="55"
        viewBox="0 0 35 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 49.8C1 52 2.8 53.8 5 53.8H29C31.2 53.8 33 52 33 49.8V43.8H1V49.8Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33 43.8099H1V13.7999C1 13.7999 1.02 13.7899 1.02 13.7999V43.7899H33V13.7999C33 13.7999 33.02 13.7899 33.02 13.7999V43.7999L33 43.8099Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 48.8101H15"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.35 5.71C25.32 2.68 21.3 1.02 17.01 1.02C12.72 1.02 8.68996 2.69 5.66996 5.71C5.66996 5.71 5.66996 5.71 5.65996 5.71C5.64996 5.71 5.65996 5.71 5.65996 5.7C8.68996 2.67 12.72 1 17.01 1C21.3 1 25.34 2.67 28.37 5.7C28.37 5.7 28.37 5.7 28.37 5.71H28.35Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.46002 9.56988C8.46002 9.56988 8.46002 9.53988 8.47002 9.52988C13.17 4.82988 20.81 4.82988 25.51 9.52988C25.53 9.54988 25.53 9.57988 25.51 9.59988C25.49 9.61988 25.46 9.61988 25.44 9.59988C20.78 4.93988 13.2 4.93988 8.54002 9.59988C8.52002 9.61988 8.49002 9.61988 8.47002 9.59988C8.47002 9.59988 8.46002 9.57988 8.46002 9.55988V9.56988Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.65 13.75C11.65 13.75 11.65 13.72 11.66 13.71C13.09 12.28 14.98 11.5 17 11.5C19.02 11.5 20.91 12.29 22.34 13.71C22.36 13.73 22.36 13.76 22.34 13.78C22.32 13.8 22.29 13.8 22.27 13.78C20.86 12.37 18.99 11.6 17 11.6C15.01 11.6 13.14 12.37 11.73 13.78C11.71 13.8 11.68 13.8 11.66 13.78C11.66 13.78 11.65 13.76 11.65 13.74V13.75Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default PhoneIcon;
