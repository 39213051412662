import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const ManagementIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="43" cy="43" r="43" fill="#B9C1D1" />
        <path
          d="M27.4733 66C25.5599 66 24 64.4423 24 62.5082V23.4918C24 21.5577 25.5495 20 27.4733 20H48.7707C49.6858 20 50.5697 20.3659 51.2248 21.0245L59.9809 29.8273C60.636 30.4859 61 31.3641 61 32.2945V56.2145C61 56.7582 60.5632 57.1973 60.0225 57.1973C59.4817 57.1973 59.045 56.7582 59.045 56.2145V32.9009C59.045 32.1064 58.733 31.3536 58.1714 30.7891L50.2681 22.8436C49.717 22.2895 48.9578 21.9759 48.1675 21.9759H28.9292C27.2861 21.9759 25.9654 23.3141 25.9654 24.9555V61.0445C25.9654 62.6964 27.2965 64.0241 28.9292 64.0241H54.1678C54.7085 64.0241 55.1453 64.4632 55.1453 65.0068C55.1453 65.5505 54.7085 65.9895 54.1678 65.9895H27.4733V66Z"
          fill="#152253"
        />
        <path
          d="M49.8002 32.2423C49.2594 32.2423 48.8226 31.8032 48.8226 31.2595V21.9759C48.8226 21.4323 49.2594 20.9932 49.8002 20.9932C50.3409 20.9932 50.7777 21.4323 50.7777 21.9759V29.7959C50.7777 30.0573 50.9961 30.2768 51.256 30.2768H60.0121C60.5528 30.2768 60.9896 30.7159 60.9896 31.2595C60.9896 31.8032 60.5528 32.2423 60.0121 32.2423H49.8002Z"
          fill="#152253"
        />
        <path
          d="M45.0062 57.0509C37.8516 58.2427 31.7265 52.085 32.912 44.8923C33.6296 40.5327 37.1237 37.02 41.4601 36.2986C48.6147 35.1068 54.7397 41.2646 53.5646 48.4677C52.8471 52.8273 49.353 56.34 45.0166 57.0614L45.0062 57.0509ZM44.9022 38.285C38.9227 37.135 33.744 42.3414 34.8879 48.3527C35.5222 51.7191 38.226 54.4268 41.5745 55.075C47.554 56.225 52.7327 51.0186 51.5888 45.0073C50.9545 41.6409 48.2507 38.9332 44.9022 38.285Z"
          fill="#152253"
        />
        <path
          d="M45.0062 57.0509C37.8516 58.2427 31.7265 52.085 32.912 44.8923C33.6296 40.5327 37.1237 37.02 41.4601 36.2986C48.6147 35.1068 54.7397 41.2646 53.5646 48.4677C52.8471 52.8273 49.353 56.34 45.0166 57.0614L45.0062 57.0509ZM44.9022 38.285C38.9227 37.135 33.744 42.3414 34.8879 48.3527C35.5222 51.7191 38.226 54.4268 41.5745 55.075C47.554 56.225 52.7327 51.0186 51.5888 45.0073C50.9545 41.6409 48.2507 38.9332 44.9022 38.285Z"
          fill="#152253"
        />
        <path
          d="M59.253 61.3792L51.2872 53.371C53.0655 51.2174 54.0014 48.3528 53.6062 45.2687C52.9927 40.4701 49.0202 36.6333 44.2263 36.1837C37.6644 35.5669 32.1945 41.0764 32.808 47.6733C33.2552 52.4928 37.0717 56.476 41.8449 57.1033C44.9126 57.5005 47.7619 56.5596 49.9042 54.7719L57.8699 62.7801C58.0571 62.9683 58.2962 63.0728 58.5666 63.0728C58.837 63.0728 59.0762 62.9683 59.2634 62.7801C59.6481 62.3933 59.6481 61.766 59.2634 61.3896L59.253 61.3792ZM34.8879 48.3424C33.744 42.331 38.9227 37.1246 44.9022 38.2746C48.2507 38.9124 50.9441 41.6305 51.5888 44.9969C52.7327 51.0083 47.554 56.2146 41.5745 55.0646C38.226 54.4269 35.5326 51.7087 34.8879 48.3424Z"
          fill="#152253"
        />
        <path
          d="M47.8555 46.6069C47.3148 46.6069 46.878 46.1678 46.878 45.6242C46.878 44.1501 45.6821 42.9374 44.2055 42.9374C43.6647 42.9374 43.2279 42.4983 43.2279 41.9547C43.2279 41.411 43.6647 40.9719 44.2055 40.9719C46.7636 40.9719 48.8331 43.0628 48.8331 45.6242C48.8331 46.1678 48.3963 46.6069 47.8555 46.6069Z"
          fill="#152253"
        />
      </svg>
    </Box>
  );
};

export default ManagementIcon;
