import { Box, Collapse, Grid, keyframes } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import ContentArea from "./ContentArea";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OperationSchema from "./assets/images/operation-schema";
import DetailPage02 from "./assets/images/detail-side-page-02";
import OperationIcon from "./assets/images/operation-icon";
import ManagementIcon from "./assets/images/management-icon";
import IntegrationIcon from "./assets/images/integration-icon";
import InteligenceIcon from "./assets/images/inteligence-icon";
import AppIcon from "./assets/images/app-icon";
import { useState } from "react";
import InfrastructureIcon from "./assets/images/infrastructure-icon";
import DetailPage02Mobile from "./assets/images/detail-side-page-02-mobile";
import OperationSchemaMobile from "./assets/images/operation-schema-mobile";

interface BoxDetailProps {
  color: string;
}

const BoxDetail: React.FC<BoxDetailProps> = (props: BoxDetailProps) => {
  return (
    <Box
      sx={{
        width: "60%",
        height: "10px",
        backgroundColor: props.color,
        borderTopRightRadius: "40px",
        borderTopLeftRadius: "40px",
        position: "absolute",
        bottom: 0,
      }}
    ></Box>
  );
};

const InfoSection01: React.FC = () => {
  let [cards, setCards] = useState([
    {
      title: "Infraestrutura",
      contentBoderColor: "#9578F3",
      text: (
        <Typography component="div" color="text.secondary" fontWeight={300} variant="body2">
          Os{" "}
          <Box display="inline" fontWeight={700}>
            medidores inteligentes
          </Box>{" "}
          ultrassônicos da série IoTA são extremamente precisos.
        </Typography>
      ),
      icon: <InfrastructureIcon />,
      showContent: false,
      bottomDetail: <BoxDetail color="#9578F3" />,
    },
    {
      title: "Operação",
      contentBoderColor: "success.light",
      text: (
        <Typography
          component="div"
          color="text.secondary"
          fontWeight={300}
          sx={{ fontSize: "1.4rem !important" }}
        >
          A Flowen realiza a{" "}
          <Box display="inline" fontWeight={700}>
            operação
          </Box>{" "}
          e o{" "}
          <Box display="inline" fontWeight={700}>
            monitoramento
          </Box>{" "}
          de toda a rede conectada, através da visualização de consumo, do comando de corte remoto e
          de outros benefícios.
        </Typography>
      ),
      icon: <OperationIcon />,
      showContent: false,
      bottomDetail: <BoxDetail color="success.light" />,
    },
    {
      title: "Gestão",
      contentBoderColor: "info.dark",
      text: (
        <Typography component="div" color="text.secondary" fontWeight={300} variant="body2">
          A plataforma{" "}
          <Box display="inline" fontWeight={700}>
            eleva a informação gerencial
          </Box>{" "}
          com um{" "}
          <Box display="inline" fontWeight={700}>
            dashboard completo,
          </Box>{" "}
          contendo o histórico de rede, perfil de comportamento e mais.
        </Typography>
      ),
      icon: <ManagementIcon />,
      showContent: false,
      bottomDetail: <BoxDetail color="info.dark" />,
    },
    {
      title: "Integração",
      contentBoderColor: "info.main",
      text: (
        <Typography component="div" color="text.secondary" fontWeight={300} variant="body2">
          O{" "}
          <Box display="inline" fontWeight={700}>
            API Flowen integra
          </Box>{" "}
          os sistemas legados, ERPs e billing, automatizando a operação e unindo todas as
          informações.
        </Typography>
      ),
      icon: <IntegrationIcon />,
      showContent: false,
      bottomDetail: <BoxDetail color="info.main" />,
    },
    {
      title: "Inteligência",
      contentBoderColor: "secondary.main",
      text: (
        <Typography component="div" color="text.secondary" fontWeight={300} variant="body2">
          Os{" "}
          <Box display="inline" fontWeight={700}>
            dados coletados
          </Box>{" "}
          permitem a criação de{" "}
          <Box display="inline" fontWeight={700}>
            estratégias valiosas de mercado,
          </Box>{" "}
          além de fornecerem previsões a longo prazo e análises aprofundadas dos consumidores.
        </Typography>
      ),
      icon: <InteligenceIcon />,
      showContent: false,
      bottomDetail: <BoxDetail color="secondary.main" />,
    },
    {
      title: "Aplicativo",
      contentBoderColor: "success.main",
      text: (
        <Typography component="div" color="text.secondary" fontWeight={300} variant="body2">
          O{" "}
          <Box display="inline" fontWeight={700}>
            aplicativo móvel
          </Box>{" "}
          é utilizado em campo para a instalação e manutenção dos medidores, facilitando a operação.
        </Typography>
      ),
      icon: <AppIcon />,
      showContent: false,
      bottomDetail: <BoxDetail color="success.main" />,
    },
  ]);

  return (
    <Box
      id="InfoSection01"
      sx={{
        py: { xs: 5, sm: 15 },
        position: "relative",
        // background: "linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%)",
      }}
    >
      <ContentArea>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              component="div"
              variant="h2"
              color="primary"
              fontWeight={300}
              sx={{ maxWidth: "545px" }}
            >
              Vamos otimizar a{" "}
              <Box fontWeight={700} display="inline">
                gestão da água?
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Typography
              component="div"
              color="text.primary"
              variant="h5"
              fontWeight={300}
              sx={{ maxWidth: "415px", marginLeft: "auto" }}
            >
              A Flowen oferece uma{" "}
              <Box fontWeight={700} display="inline">
                solução completa para as
              </Box>{" "}
              <Box fontWeight={700} display="inline">
                concessionárias de água{" "}
              </Box>
              com tecnologias integradas e produtos que automatizam a operação e reduzem custos.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} sx={{ my: 10 }}>
            <OperationSchema
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <OperationSchemaMobile
              sx={{
                display: { xs: "flex", sm: "none" },
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <DetailPage02
              sx={{
                position: "absolute",
                left: 0,
                top: "25rem",
                display: { xs: "none", sm: "block" },
              }}
            />
            <DetailPage02Mobile
              sx={{
                position: "absolute",
                left: 0,
                top: "33rem",
                display: { xs: "block", sm: "none" },
              }}
            />
          </Grid>

          <Grid container spacing={4}>
            {cards.map((card, index) => {
              return (
                <Grid item xs={12} sm={4} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: card.showContent ? "center" : "space-between",
                      minHeight: "350px",
                      maxWidth: "350px",
                      boxShadow: 2,
                      borderRadius: "40px",
                      py: 3,
                      px: 5,
                      position: "relative",
                      cursor: "pointer",
                      borderColor: card.showContent ? card.contentBoderColor : "",
                      borderWidth: card.showContent ? "5px" : "",
                      borderStyle: card.showContent ? "solid" : "",
                    }}
                    onClick={() => {
                      let newCardValue = [...cards];
                      newCardValue[index].showContent = !newCardValue[index].showContent;
                      setCards(newCardValue);
                    }}
                  >
                    <Collapse
                      timeout={1000}
                      in={card.showContent}
                      sx={{
                        display: card.showContent ? "flex" : "none",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          wordBreak: "break-word",
                        }}
                      >
                        {card.text}
                      </Box>
                    </Collapse>

                    {!card.showContent && (
                      <Box sx={{ display: "contents", position: "relative" }}>
                        {card.icon}
                        <Typography color="primary" fontWeight={700} variant="subtitle2">
                          {card.title}
                        </Typography>
                        <KeyboardArrowUpIcon sx={{ color: "primary.main", fontSize: "2.5rem" }} />
                        {card.bottomDetail}
                      </Box>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </ContentArea>
    </Box>
  );
};

export default InfoSection01;
