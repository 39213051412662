import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const Money: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="92"
        height="82"
        viewBox="0 0 92 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M65.4042 33.702H55.8855L72.5733 6.4978L89.2612 33.702H79.7425"
          stroke="white"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 33.7021C2 51.2106 16.1935 65.4042 33.7021 65.4042C51.2106 65.4042 65.4042 51.2106 65.4042 33.7021C65.4042 16.1935 51.2106 2 33.7021 2C16.1935 2 2 16.1935 2 33.7021Z"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.7863 25.342C39.6402 16.9578 25.4467 17.9475 25.3421 26.6857C25.2938 30.5962 29.8721 32.3905 33.7101 32.9939C47.6703 35.1986 43.8081 48.322 33.7101 47.8231C26.0421 47.4449 23.8294 42.2632 23.596 41.0241"
          fill="white"
        />
        <path
          d="M42.7863 25.342C39.6402 16.9578 25.4467 17.9475 25.3421 26.6857C25.2938 30.5962 29.8721 32.3905 33.7101 32.9939C47.6703 35.1986 43.8081 48.322 33.7101 47.8231C26.0421 47.4449 23.8294 42.2632 23.596 41.0241"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.863 12.5244V19.5649"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.863 47.8474V54.8798"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M65.4042 33.702H55.8855L72.5733 6.4978L89.2612 33.702H79.7425" fill="white" />
        <path
          d="M65.4042 33.702H55.8855L72.5733 6.4978L89.2612 33.702H79.7425"
          stroke="white"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65.4042 33.702H55.8855L72.5733 6.4978L89.2612 33.702H79.7425"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.7425 33.702C79.7425 59.2327 58.9752 79.9999 33.4366 79.9999"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.7021 65.4041C51.2106 65.4041 65.4041 51.2106 65.4041 33.702"
          stroke="#00BEF0"
          strokeWidth="3.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default Money;
