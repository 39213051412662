import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const BorderElementBottom: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="237"
        height="108"
        viewBox="0 0 237 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 98.813L160.833 98.813C198.276 98.813 228.632 68.4573 228.632 31.0209L228.632 8.99993"
          stroke="url(#paint0_linear_261_2056)"
          strokeWidth="16.4667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="9" cy="99" r="9" fill="#E0E8F3" />
        <defs>
          <linearGradient
            id="paint0_linear_261_2056"
            x1="12"
            y1="53.9109"
            x2="228.632"
            y2="53.9109"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00BEF0" />
            <stop offset="1" stopColor="#C8F0C8" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default BorderElementBottom;
