import { Box, Link } from "@mui/material";
import BannerSection from "./BannerSection";
import CTA01 from "./CTA01";
import FlowenPlatform from "./FlowenPlatform";
import Footer from "./Footer";
import InfoSection01 from "./InfoSection01";
import InfoSection02 from "./InfoSection02";
import InfoSection03 from "./InfoSection03";
import InfoSection04 from "./InfoSection04";
import ThemeCustomization from "./theme";
import WhoWeAre from "./WhoWeAre";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const App = () => {
  return (
    <ThemeCustomization>
      <BannerSection />
      <Box sx={{ background: "linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%)" }}>
        <InfoSection01 />
        <InfoSection02 />
      </Box>
      <FlowenPlatform />
      <InfoSection03 />
      <InfoSection04 />
      <WhoWeAre />
      <CTA01 />
      <Footer />

      <Link
        href="#BannerSection"
        sx={{
          cursor: "pointer",
          position: "fixed",
          borderRadius: "50%",
          backgroundColor: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "56px",
          height: "56px",
          border: "initial",
          right: "1rem",
          bottom: "1rem",
        }}
      >
        <ArrowUpwardIcon sx={{ color: "primary.contrastText" }} />
      </Link>
    </ThemeCustomization>
  );
};
export default App;
