import { Box, Button, Grid, Link, Typography } from "@mui/material";
import ContentArea from "./ContentArea";
import BGCTA01 from "./assets/images/bg-cta-01.png";
import DetailCTA from "./assets/images/detail-cta";
import DetailCTAMobile from "./assets/images/detail-cta-mobile";

const CTA01: React.FC = () => {
  return (
    <Box
      id="CTA01Section"
      sx={{
        backgroundImage: `url(${BGCTA01})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        py: { xs: 10, sm: 15 },
      }}
    >
      <DetailCTA
        sx={{ position: "absolute", right: 0, top: "-2rem", display: { sm: "block", xs: "none" } }}
      />
      <DetailCTAMobile
        sx={{ position: "absolute", right: 0, top: "-.5rem", display: { sm: "none", xs: "block" } }}
      />
      <ContentArea
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={7}>
            <Typography
              variant="subtitle1"
              color="primary.contrastText"
              fontWeight={700}
              sx={{ maxWidth: "390px" }}
            >
              Vamos juntos{" "}
              <Box display="inline" fontWeight={300}>
                mudar o curso dessa história em direção a um futuro{" "}
              </Box>
              tecnológico e sustentável?
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            sx={{ mt: { xs: 10, sm: 0 } }}
          >
            <Typography
              component="div"
              variant="h5"
              color="primary.contrastText"
              sx={{ maxWidth: "370px" }}
            >
              Nós podemos te mostrar como.{" "}
              <Box fontWeight={700} display="inline">
                Nós somos a Flowen.
              </Box>
            </Typography>
            <Link
              href="mailto:comercial@flowenwater.com"
              sx={{
                backgroundColor: "success.main",
                width: { xs: "100%", sm: "350px" },
                borderRadius: "60px",
                paddingY: 2.5,
                mt: 4,
                color: "primary.main",
                textAlign: "center",
                textTransform: "initial",
                textDecoration: "none",
                "&:hover": {
                  color: "primary.contrastText",
                  backgroundColor: "primary.main",
                },
              }}
            >
              <Typography variant="h6" fontWeight="700" color="inherit">
                Fale conosco
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </ContentArea>
    </Box>
  );
};

export default CTA01;
