import { Box } from "@mui/material";
import { keyframes } from "@mui/system";

const blink = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const AnimatedCircle: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: { xs: "16px", sm: "20px" },
        height: { xs: "16px", sm: "20px" },
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "info.main",
        borderRadius: "50%",
        mt: 1,
      }}
    >
      <Box
        sx={{
          width: { xs: "6px", sm: "8px" },
          height: { xs: "6px", sm: "8px" },
          backgroundColor: "info.main",
          borderRadius: "50%",
          animation: `${blink} .8s cubic-bezier(.5, 0, 1, 1) infinite alternate`,
        }}
      ></Box>
    </Box>
  );
};

export default AnimatedCircle;
