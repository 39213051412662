import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const DetailTopPage: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="686"
        height="99"
        viewBox="0 0 686 99"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_231_426"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="686"
          height="99"
        >
          <rect width="686" height="99" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_231_426)">
          <mask
            id="mask1_231_426"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="-16"
            y="-643"
            width="767"
            height="732"
          >
            <path d="M-16 -643L-16 89L751 89L751 -643L-16 -643Z" fill="white" />
          </mask>
          <g mask="url(#mask1_231_426)">
            <path
              d="M155.936 68.6953L155.936 -47.933C155.936 -73.1334 135.505 -93.5647 110.305 -93.5647L32.3604 -93.5647"
              stroke="#96C8AA"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M284.218 -25.1204L284.218 73.002"
              stroke="#96C8AA"
              strokeWidth="12.6667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M621.557 68.6953L621.557 -25.2217C621.557 -75.5907 601.056 -121.305 568.11 -154.251C550.231 -172.13 528.59 -186.342 504.491 -195.601"
              stroke="#96C8AA"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M621.557 68.6953L621.557 -25.2153"
              stroke="#96C8AA"
              strokeWidth="12.6667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M121.686 68.6953L121.686 -93.5647C121.686 -118.765 142.117 -139.196 167.317 -139.196C192.518 -139.196 212.949 -118.765 212.949 -93.5647"
              stroke="#EDEDED"
              strokeWidth="16.4667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M655.807 68.6953L655.807 -80.138C655.807 -117.581 686.163 -147.936 723.599 -147.936L745.62 -147.936"
              stroke="#EDEDED"
              strokeWidth="16.4667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M249.967 -224.994L249.967 73.002"
              stroke="#C8F0C8"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M587.306 68.6953L587.306 -215.671"
              stroke="#C8F0C8"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M397.097 -104.939L439.081 -104.939C461.102 -104.939 480.988 -96.0537 495.454 -81.5884C509.919 -67.123 518.805 -47.2427 518.805 -25.2154L518.805 68.6953"
              stroke="#C8F0C8"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M621.557 8.0979L717.361 8.0979"
              stroke="#C8F0C8"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M157.19 -24.1767L324.504 -24.1767C349.451 -24.1767 369.674 -3.95435 369.674 20.9926L369.674 68.6953"
              stroke="#EDEDED"
              strokeWidth="16.4667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M550.3 68.6953L550.3 -24.2717C550.3 -86.5854 499.786 -137.094 437.478 -137.094"
              stroke="#EDEDED"
              strokeWidth="16.4667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M87.435 68.6953L87.435 -93.5647C87.435 -115.611 96.4157 -135.631 110.83 -150.045C125.245 -164.46 145.265 -173.441 167.311 -173.441"
              stroke="#50C8F0"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M410.733 68.6953C410.733 53.7613 416.743 40.303 426.56 30.4863C436.376 20.6697 449.841 14.6593 464.769 14.6593"
              stroke="#50C8F0"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M518.805 68.6953C518.805 53.7613 512.794 40.303 502.978 30.4863C493.161 20.6697 479.696 14.6593 464.769 14.6593"
              stroke="#50C8F0"
              strokeWidth="16.4667"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M621.557 -0.464684C626.288 -0.464684 630.119 3.36698 630.119 8.09798C630.119 12.829 626.288 16.6606 621.557 16.6606C616.826 16.6606 612.994 12.829 612.994 8.09798C612.994 3.36698 616.826 -0.464684 621.557 -0.464684Z"
              fill="#EDEDED"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
};

export default DetailTopPage;
