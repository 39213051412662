import { Container, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

interface ContentAreaProps {
  children?: ReactElement;
  sx?: SxProps<Theme> | undefined;
}

const ContentArea: React.FC<ContentAreaProps> = ({ children, sx }) => {
  return (
    <Container maxWidth="lg" sx={{ ...sx, px: { xs: "14px", sm: "inherith" } }}>
      {children}
    </Container>
  );
};

export default ContentArea;
