import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const InteligenceIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="43" cy="43" r="43" fill="#EBEBEF" />
        <path
          d="M40.0497 23.224C30.5141 23.921 23 31.8743 23 41.5841C23 51.2939 31.246 59.9999 41.4159 59.9999C51.5857 59.9999 59.0789 52.4928 59.7759 42.9503"
          stroke="#152253"
          strokeWidth="2.73409"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64 37.4159C64 27.246 55.754 19 45.5842 19V37.4159H64Z"
          stroke="#152253"
          strokeWidth="2.73409"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default InteligenceIcon;
