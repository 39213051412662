import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const BorderElementTop: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="364"
        height="108"
        viewBox="0 0 364 108"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M354.552 8.99994L76.4238 8.99994C38.9812 8.99994 8.63184 39.3556 8.63184 76.792L8.63184 98.813"
          stroke="#9578F3"
          strokeWidth="16.4667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <circle cx="355" cy="9" r="9" fill="#E0E8F3" />
      </svg>
    </Box>
  );
};

export default BorderElementTop;
