import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import ContentArea from "./ContentArea";
import DetailSidePage from "./assets/images/detail-side-page";
import Bulb from "./assets/images/bulb";
import Cog from "./assets/images/cog";
import Money from "./assets/images/money";
import DetailSidePageMobile from "./assets/images/detail-side-page-mobile";

const InfoSection02: React.FC = () => {
  return (
    <Box id="InfoSection02" sx={{ py: { xs: 5, sm: 10 }, position: "relative" }}>
      <ContentArea>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography
              component="div"
              color="primary"
              variant="h2"
              fontWeight={300}
              sx={{ maxWidth: "680px" }}
            >
              Inteligência operacional que{" "}
              <Box fontWeight={700} display="inline">
                gera resultados
              </Box>{" "}
            </Typography>
          </Grid>

          <Grid container columnSpacing={0}>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 8,
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Bulb />
              <Typography
                color="primary"
                variant="h5"
                fontWeight={700}
                sx={{ maxWidth: "280px", mt: 2 }}
              >
                Redução de custos operacionais
              </Typography>
              <Typography
                component="div"
                color="text.secondary"
                variant="h6"
                fontWeight={300}
                sx={{ maxWidth: "285px", mt: 2 }}
              >
                <Box fontWeight={700} display="inline">
                  Tecnologias como a válvula de corte remoto integrada{" "}
                </Box>
                e a medição remota eliminam a necessidade de contratar equipes de corte e
                religamento, além da realização da leitura presencial.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 8,
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Cog />
              <Typography
                color="primary"
                variant="h5"
                fontWeight={700}
                sx={{ maxWidth: "280px", mt: 2 }}
              >
                Manutenção inteligente e preditiva
              </Typography>
              <Typography
                component="div"
                color="text.secondary"
                variant="h6"
                fontWeight={300}
                sx={{ maxWidth: "320px", mt: 2 }}
              >
                <Box fontWeight={700} display="inline">
                  Avisos on-line são disparados{" "}
                </Box>
                assim que ocorre algum imprevisto, indicando os medidores e a região em que há
                ocorrências como falta de água ou vazamentos. Temos também a previsão de consumo e
                outros insights sobre a rede e os consumidores.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 8,
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Money />
              <Typography
                color="primary"
                variant="h5"
                fontWeight={700}
                sx={{ maxWidth: "280px", mt: 2 }}
              >
                Aumento no faturamento
              </Typography>
              <Typography
                component="div"
                color="text.secondary"
                variant="h6"
                fontWeight={300}
                sx={{ maxWidth: "285px", mt: 2 }}
              >
                <Box fontWeight={700} display="inline">
                  Medidores de alta precisão{" "}
                </Box>
                acompanham o consumo de água hora a hora, eliminando a submedição e, por
                consequência, aumentam a lucratividade das concessionárias.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ContentArea>
      <DetailSidePage
        sx={{ position: "absolute", right: 0, top: "100px", display: { xs: "none", sm: "block" } }}
      />
      <DetailSidePageMobile
        sx={{ position: "absolute", right: 0, top: "100px", display: { xs: "block", sm: "none" } }}
      />
    </Box>
  );
};

export default InfoSection02;
