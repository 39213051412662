import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const InfrastructureIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="43" cy="43" r="43" fill="#A690F4" fillOpacity="0.3" />
        <path
          d="M57.6672 23C64.7778 30.1106 64.7778 41.6286 57.6672 48.7245"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.0529 26.6287C59.1654 31.7412 59.1654 40.0125 54.0529 45.1104"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.351 30.3311C53.4214 33.4015 53.4214 38.3672 50.351 41.423"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.873 23C20.7624 30.1106 20.7624 41.6286 27.873 48.7392"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.487 26.6287C26.3744 31.7412 26.3744 40.0125 31.487 45.1104"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.1892 30.3311C32.1187 33.4015 32.1187 38.3672 35.1892 41.423"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43 58.5061L43 39.5061"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.7701 38.7492C44.3604 38.7492 45.6496 37.46 45.6496 35.8697C45.6496 34.2794 44.3604 32.9902 42.7701 32.9902C41.1798 32.9902 39.8906 34.2794 39.8906 35.8697C39.8906 37.46 41.1798 38.7492 42.7701 38.7492Z"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.7385 64.7352H25.8015C25.8015 61.2975 28.5929 58.5061 32.0306 58.5061H53.4947C56.9324 58.5061 59.7238 61.2975 59.7238 64.7352H59.7385Z"
          stroke="#152253"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default InfrastructureIcon;
