import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const FileIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="44"
        height="55"
        viewBox="0 0 44 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29 1V11H39L29 1Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 40C20 34.48 24.48 30 30 30C33.97 30 37.38 32.32 39 35.66V11H29V1H5C2.79 1 1 2.79 1 5V45C1 47.21 2.79 49 5 49H25.66C22.31 47.38 20 43.97 20 40Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 35.01H9"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 29.01H9"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31 23.01H9"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31 17.01H9"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 50C35.5228 50 40 45.5228 40 40C40 34.4772 35.5228 30 30 30C24.4772 30 20 34.4772 20 40C20 45.5228 24.4772 50 30 50Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.27 48.74L38.61 45.08C37.75 46.54 36.53 47.75 35.07 48.62L38.73 52.28C39.7 53.25 41.29 53.25 42.27 52.28C43.24 51.31 43.24 49.72 42.27 48.74Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default FileIcon;
