import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import GlobalStyles from "./globalStyles";
import Palette from "./palette";
import typography from "./typography";

const ThemeCustomization = ({ children }: any) => {
  const theme = createTheme({
    palette: Palette(),
    typography: typography,
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1266,
        xl: 1536,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles theme={theme} />
      {children}
    </ThemeProvider>
  );
};

export default ThemeCustomization;
