import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const OperationIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="86"
        height="86"
        viewBox="0 0 86 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="43" cy="43" r="43" fill="#D3F3D3" />
        <path
          d="M20 57.8401H65.9998"
          stroke="#152253"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.2032 27H25.7895C25.0125 27 24.3826 27.6299 24.3826 28.4069V50.8124C24.3826 51.5894 25.0125 52.2193 25.7895 52.2193H60.2032C60.9802 52.2193 61.6101 51.5894 61.6101 50.8124V28.4069C61.6101 27.6299 60.9802 27 60.2032 27Z"
          stroke="#152253"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.6733 39.543V45.234"
          stroke="#152253"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.9541 37.9531V45.234"
          stroke="#152253"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.3266 34.1543V45.2339"
          stroke="#152253"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default OperationIcon;
