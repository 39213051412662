import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const DetailSidePageMobile: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="70"
        height="58"
        viewBox="0 0 70 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.7493 41.6875C64.0193 41.6875 68.7729 43.8094 72.2386 47.2751C75.7042 50.7407 77.8261 55.4943 77.8261 60.7644L77.8261 87.2042"
          stroke="#96C8AA"
          strokeWidth="5.81339"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M77.8261 22.6106C77.8261 17.3383 75.7042 12.5869 72.2386 9.12125C68.7729 5.65558 64.0193 3.53369 58.7493 3.53369"
          stroke="#A690F4"
          strokeWidth="5.81339"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.6724 22.6106C39.6724 17.3383 41.7943 12.5869 45.26 9.12125C48.7256 5.65558 53.4792 3.53369 58.7493 3.53369"
          stroke="#A690F4"
          strokeWidth="5.81339"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.76576 41.6875L58.7493 41.6875"
          stroke="#50C8F0"
          strokeWidth="5.81339"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M58.7493 41.6876C53.477 41.6876 48.7256 39.5657 45.26 36.1C41.7943 32.6344 39.6724 27.8808 39.6724 22.6107"
          stroke="#50C8F0"
          strokeWidth="5.81339"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M58.7493 44.7104C57.0791 44.7104 55.7263 43.3576 55.7263 41.6874C55.7263 40.0172 57.0791 38.6644 58.7493 38.6644C60.4195 38.6644 61.7723 40.0172 61.7723 41.6874C61.7723 43.3576 60.4195 44.7104 58.7493 44.7104Z"
          fill="#C8F0C8"
        />
        <path
          d="M39.6724 25.6336C38.0022 25.6336 36.6494 24.2808 36.6494 22.6106C36.6494 20.9404 38.0022 19.5876 39.6724 19.5876C41.3426 19.5876 42.6954 20.9404 42.6954 22.6106C42.6954 24.2808 41.3426 25.6336 39.6724 25.6336Z"
          fill="#C8F0C8"
        />
      </svg>
    </Box>
  );
};

export default DetailSidePageMobile;
