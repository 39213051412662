import { PaletteOptions } from "@mui/material";
import { grey } from "@mui/material/colors";

const Palette = (): PaletteOptions => {
  return {
    primary: {
      main: "#152253",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#4B4683",
    },
    divider: grey[300],
    success: {
      main: "#96C8AA",
      light: "#C8F0C8",
    },
    info: {
      main: "#00BEF0",
      dark: "#090F3E",
    },
    text: {
      primary: "#404040",
      secondary: "#454545",
    },
    background: {
      default: "#ffffff",
    },
    grey: grey,
    error: {
      main: "#f44336",
    },
  };
};

export default Palette;
