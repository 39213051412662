import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const FlowenConnectIcon: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32" cy="32" r="32" fill="#50C8F0" />
        <path
          d="M37.3684 35.003C39.6778 32.6935 39.6778 28.9492 37.3684 26.6397C35.0589 24.3302 31.3145 24.3302 29.0051 26.6397C26.6956 28.9492 26.6956 32.6935 29.0051 35.003C31.3145 37.3125 35.0589 37.3125 37.3684 35.003Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.6237 48.5667C37.6237 46.1125 35.6367 44.1334 33.1904 44.1334C30.7442 44.1334 28.7571 46.1204 28.7571 48.5667C28.7571 51.0129 30.7442 53 33.1904 53C35.6367 53 37.6237 51.0129 37.6237 48.5667Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4333 26.3762C17.8875 26.3762 19.8667 28.3633 19.8667 30.8096C19.8667 33.2558 17.8796 35.2429 15.4333 35.2429C12.9871 35.2429 11 33.2558 11 30.8096C11 28.3633 12.9871 26.3762 15.4333 26.3762Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.07 22.3942C23.1119 22.3942 24.7671 20.7389 24.7671 18.6971C24.7671 16.6552 23.1119 15 21.07 15C19.0282 15 17.3729 16.6552 17.3729 18.6971C17.3729 20.7389 19.0282 22.3942 21.07 22.3942Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.3029 46.6271C47.3448 46.6271 49 44.9718 49 42.93C49 40.8881 47.3448 39.2329 45.3029 39.2329C43.2611 39.2329 41.6058 40.8881 41.6058 42.93C41.6058 44.9718 43.2611 46.6271 45.3029 46.6271Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.3029 21.6579C46.9381 21.6579 48.2637 20.3323 48.2637 18.6971C48.2637 17.0619 46.9381 15.7363 45.3029 15.7363C43.6677 15.7363 42.342 17.0619 42.342 18.6971C42.342 20.3323 43.6677 21.6579 45.3029 21.6579Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.23 36.7313H33.1508V44.1254H33.23V36.7313Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.2688 30.778H19.8746V30.8571H27.2688V30.778Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2328 21.8052L24.1768 21.8611L28.9742 26.6586L29.0302 26.6026L24.2328 21.8052Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.4048 34.9706L37.3488 35.0266L42.1462 39.824L42.2022 39.7681L37.4048 34.9706Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.1821 20.7544L37.3379 26.5986L37.3939 26.6546L43.2381 20.8104L43.1821 20.7544Z"
          stroke="#152253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export default FlowenConnectIcon;
