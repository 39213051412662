import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const DetailTopPageMobile: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="320"
        height="64"
        viewBox="0 0 320 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_302_154"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="320"
          height="64"
        >
          <rect width="320" height="64" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_302_154)">
          <mask
            id="mask1_302_154"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="-71"
            y="-412"
            width="492"
            height="469"
          >
            <path
              d="M-70.2391 -411.483L-70.239 56.9546L420.596 56.9546L420.596 -411.483L-70.2391 -411.483Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_302_154)">
            <path
              d="M39.7902 43.9614L39.7902 -30.6739C39.7902 -46.8007 26.7153 -59.8755 10.5886 -59.8755L-39.2913 -59.8755"
              stroke="#96C8AA"
              strokeWidth="10.5377"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M121.883 -16.0753L121.883 46.7173"
              stroke="#96C8AA"
              strokeWidth="8.10593"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M337.76 43.9614L337.76 -16.14C337.76 -48.3732 324.641 -77.6275 303.557 -98.711C292.116 -110.153 278.267 -119.247 262.845 -125.173"
              stroke="#96C8AA"
              strokeWidth="10.5377"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M17.8717 43.9614L17.8717 -59.8755C17.8717 -76.0023 30.9466 -89.0771 47.0733 -89.0771C63.2001 -89.0771 76.2749 -76.0023 76.2749 -59.8755"
              stroke="#EDEDED"
              strokeWidth="10.5377"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M99.9645 -143.983L99.9645 46.7173"
              stroke="#C8F0C8"
              strokeWidth="10.5377"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M315.842 43.9614L315.842 -138.017"
              stroke="#C8F0C8"
              strokeWidth="10.5377"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M194.119 -67.1546L220.986 -67.1546C235.078 -67.1546 247.805 -61.4683 257.061 -52.2114C266.318 -42.9544 272.005 -30.2321 272.005 -16.1359L272.005 43.9614"
              stroke="#C8F0C8"
              strokeWidth="10.5377"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M40.5927 -15.4712L147.664 -15.4712C163.628 -15.4712 176.57 -2.53012 176.57 13.4345L176.57 43.9614"
              stroke="#EDEDED"
              strokeWidth="10.5377"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M292.16 43.9614L292.16 -15.532C292.16 -55.4091 259.834 -87.7315 219.961 -87.7315"
              stroke="#EDEDED"
              strokeWidth="10.5377"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M-4.04668 43.9614L-4.04668 -59.8755C-4.04668 -73.9839 1.70042 -86.7953 10.925 -96.0199C20.1495 -105.244 32.9609 -110.992 47.0693 -110.992"
              stroke="#50C8F0"
              strokeWidth="10.5377"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M202.845 43.9614C202.845 34.4045 206.691 25.792 212.973 19.5099C219.255 13.2278 227.872 9.38154 237.425 9.38154"
              stroke="#50C8F0"
              strokeWidth="10.5377"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M272.005 43.9614C272.005 34.4045 268.159 25.792 261.876 19.5099C255.594 13.2278 246.978 9.38154 237.425 9.38154"
              stroke="#50C8F0"
              strokeWidth="10.5377"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
};

export default DetailTopPageMobile;
