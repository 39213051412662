import { Box } from "@mui/material";
import { ImageProps } from "../../models/ImageProps";

const DetailPage02Mobile: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Box sx={{ ...props.sx }}>
      <svg
        width="68"
        height="173"
        viewBox="0 0 68 173"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_285_1050"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="68"
          height="173"
        >
          <rect width="68" height="173" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_285_1050)">
          <path
            d="M-67.3228 70.5647L-67.3228 109.405C-67.3228 121.379 -57.6157 131.086 -45.6424 131.086L1.98166 131.086"
            stroke="#F4F4F4"
            strokeWidth="5.26568"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.6652 109.18L2.33409 109.18C-9.72432 109.18 -19.5002 118.956 -19.5002 131.015L-19.5002 142.038"
            stroke="#EDEDED"
            strokeWidth="4.05052"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M1.98165 131.084L1.98166 42.1442C1.98166 30.1709 11.6887 20.4658 23.6601 20.4658L30.7019 20.4658"
            stroke="#C8F0C8"
            strokeWidth="5.26568"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M-78.2754 104.322L-78.2754 109.405C-78.2754 118.414 -74.6117 126.585 -68.7182 132.479C-62.8247 138.373 -54.6528 142.036 -45.6444 142.036L21.6652 142.036"
            stroke="#EDEDED"
            strokeWidth="5.26568"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.6652 87.8806L-28.138 87.8806"
            stroke="#50C8F0"
            strokeWidth="5.26568"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M21.6652 98.2278L1.98166 98.2278"
            stroke="#96C8AA"
            strokeWidth="5.26568"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </Box>
  );
};

export default DetailPage02Mobile;
