import { Box, Grid, Link, styled, Typography } from "@mui/material";
import { Fragment } from "react";
import IconInstragram from "./assets/images/icon-instagram";
import IconIvoire from "./assets/images/icon-ivoire";
import IconLinkedIn from "./assets/images/icon-linkedin";
import LogoBlue from "./assets/images/logo-blue";
import ContentArea from "./ContentArea";
import FooterBG from "./assets/images/footer-bg.png";

const SocialBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "10px",
}));

const Footer: React.FC = () => {
  return (
    <Box
      id="footerSection"
      sx={{
        backgroundImage: `url(${FooterBG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <ContentArea sx={{ pt: 6 }}>
        <Fragment>
          <Grid container>
            <Grid item xs={12} sm={8} textAlign={{ sm: "left", xs: "center" }}>
              <LogoBlue />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display="flex"
              alignItems="center"
              justifyContent={{ sm: "flex-end", xs: "center" }}
              sx={{ mt: { xs: 3, sm: 0 } }}
            >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Link href="https://www.linkedin.com/company/flowenwater/" target="_blank">
                  <SocialBox>
                    <IconLinkedIn />
                  </SocialBox>
                </Link>

                <Link href="https://www.instagram.com/flowenwater/" target="_blank">
                  <SocialBox>
                    <IconInstragram />
                  </SocialBox>
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 5, pb: { xs: 10, sm: 5 } }} columnSpacing={2}>
            <Grid item xs={12} sm={8} textAlign={{ sm: "left", xs: "center" }}>
              <Link href="/flowen-politica-de-privacidade.pdf" target="_blank">
                <Typography variant="caption" color="text.secondary">
                  Política de privacidade
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box
                justifyContent={{ sm: "flex-end", xs: "center" }}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="caption" color="text.secondary" sx={{ mr: 2 }}>
                  © 2023 Flowen. Desenvolvido por Ivoire
                </Typography>
                <IconIvoire />
              </Box>
            </Grid>
          </Grid>
        </Fragment>
      </ContentArea>
    </Box>
  );
};

export default Footer;
